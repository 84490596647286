import CrudTable from '../../../crud/CrudTable';
import { categoryService as service, ivatypeService, taxtypeService } from '../../../../services';
import { useTranslation } from 'react-i18next';

const initFilter = {
      name: null,
      ivadefault: null,
      tasaDefault: null,
      category: null,
      type: 1,
};

export default function CategoryParent() {
      const { t, i18n } = useTranslation();

      const createEstructureFilter = [
            {
                  type: 'input',
                  name: 'name',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'ivadefault',
                  service: ivatypeService.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'tasaDefault',
                  service: taxtypeService.combo,
                  col: 4,
            },
      ];

      return (
            <CrudTable
                  service={service}
                  urledit={'/categoriesparent/edit/'}
                  urlnew={'/categoriesparent/new/'}
                  filter={initFilter}
                  i18n={'categoriesparent'}
                  fieldstable={['code', 'name', 'ivadefault.percent', 'tasaDefault.percent', 'maxDiscount']}
                  estructureForm={createEstructureFilter}
            />
      );
}
