import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import CrudTable from '../../../crud/CrudTable';
import { budgetService as service, clientService, routeService, paymenttypeService, statebudgetService, deliverynoteService, preformService } from '../../../../services';
import PrintIcon from '@mui/icons-material/Print';
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { pad } from '../../../utils/Data';
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PublishIcon from '@mui/icons-material/Publish';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DownloadIcon from '@mui/icons-material/Download';

const moment = require('moment');

const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'white',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
};

const initFilter = {
      number: null,
      serie: null,
      client: null,
      from: moment().format('YYYY') + '-01-01T00:00:00Z',
      to: null,
      state: null,
      route: null,
      paymenttype: null,
};

export default function Budget() {
      const history = useHistory();
      const { t, i18n } = useTranslation();
      const [openBill, setOpenBill] = React.useState(false);
      const [openPreform, setOpenPreform] = React.useState(false);
      const [idBill, setIdBill] = React.useState<any>(null);

      const createEstructureFilter = [
            {
                  type: 'autocomplete',
                  name: 'route',
                  service: routeService.combo,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'serie',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'number',
                  inputtype: 'number',
                  autoFocus: true,
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'client',
                  service: clientService.combo,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'from',
                  inputtype: 'date',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'to',
                  inputtype: 'date',
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'state',
                  service: statebudgetService.combo,
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'paymenttype',
                  service: paymenttypeService.combo,
                  col: 3,
            },
      ];

      const handlePrintDirect = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            if (isGenerate) {
                  let fileName = 'Pedido' + (bill.serie ? bill.serie : '') + pad(+bill.number, 5) + '.pdf';
                  service.printDirect(bill.id, fileName, (data, erro) => {
                        toast.success('Se ha impreso el pedido', { position: toast.POSITION.TOP_CENTER });
                  });
            } else {
                  toast.error('Solo se puede imprimir pedidos ya generados', { position: toast.POSITION.TOP_CENTER });
            }
      };

      const handlePrint = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            if (isGenerate) {
                  let fileName = 'Pedido' + (bill.serie ? bill.serie : '') + pad(+bill.number, 5) + '.pdf';
                  service.print(bill.id, fileName, (data, erro) => {
                        toast.success('Se ha descargado el pedido', { position: toast.POSITION.TOP_CENTER });
                  });
            } else {
                  toast.error('Solo se puede imprimir pedidos ya generadas', { position: toast.POSITION.TOP_CENTER });
            }
      };

      const paintCell = {
            base: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            discount: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            iva: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            total: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            equivalencia: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            tax: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
      };

      const handelDelivery = (bill) => {
            setIdBill(bill.id);
            setOpenBill(!openBill);
      };

      const handelPreform = (bill) => {
            setIdBill(bill.id);
            setOpenPreform(!openPreform);
      };

      const handleCloseBill = () => {
            setOpenBill(!openBill);
      };

      const handleClosePreform = () => {
            setOpenPreform(!openPreform);
      };

      const generateDelivery = () => {
            deliverynoteService.budget(idBill, (data, error) => {
                  if (data && data.data && data.data.id) {
                        history.push('/deliverynote/edit/' + data.data.id);
                        window.location.reload();
                  } else {
                        setOpenBill(!openBill);
                        toast.error(error.mes, { position: toast.POSITION.TOP_CENTER });
                  }
            });
      };

      const generatePreform = () => {
            preformService.budget(idBill, (data, error) => {
                  if (data && data.data && data.data.id) {
                        history.push('/preform/edit/' + data.data.id);
                        window.location.reload();
                  } else {
                        setOpenPreform(!openPreform);
                        toast.error(error.mes, { position: toast.POSITION.TOP_CENTER });
                  }
            });
      };

      const conditionIsGenerate = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            return isGenerate;
      };

      const conditionDelete = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            return !isGenerate;
      };

      return (
            <>
                  <CrudTable
                        service={service}
                        urledit={'/budget/edit/'}
                        urlnew={'/budget/new/'}
                        filter={initFilter}
                        i18n={'budget'}
                        fieldstable={['serie', 'number', 'date', 'client.comercialName|client.email|client.phone', 'base', 'iva', 'equivalencia', 'total', 'haveDelivery', 'havePreform']}
                        exportfields={['number', 'date', 'client.code', 'client.comercialName', 'base', 'iva', 'equivalencia', 'total']}
                        defaultValues={{ serie: '', number: 'Borrador' }}
                        moreActions={[
                              { handle: handlePrintDirect, icon: <PrintIcon />, condition: conditionIsGenerate },
                              { handle: handlePrint, icon: <DownloadIcon />, condition: conditionIsGenerate },
                              { handle: handelDelivery, icon: <PublishIcon />, condition: conditionIsGenerate },
                              { handle: handelPreform, icon: <ReceiptLongIcon />, condition: conditionIsGenerate },
                        ]}
                        //conditionDelete={conditionDelete}
                        paintCell={paintCell}
                        labels={{ 'client.comercialName|client.email|client.phone': t('budget.field.client.name') }}
                        estructureForm={createEstructureFilter}
                        order={true}
                        expendedFilter={true}
                        orderColumns={{ serie: 'serie', number: 'number', date: 'date' }}
                  />
                  <ToastContainer />
                  <Modal open={openBill} onClose={handleCloseBill} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                        <Box sx={style}>
                              <div style={{ marginBottom: '30px' }}>¿Quieres hacer un albarán de este presupuesto?</div>
                              <div>
                                    <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={handleCloseBill}>
                                          {' '}
                                          No
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={generateDelivery}>
                                          {' '}
                                          Sí{' '}
                                    </Button>
                              </div>
                        </Box>
                  </Modal>

                  <Modal open={openPreform} onClose={handleClosePreform} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                        <Box sx={style}>
                              <div style={{ marginBottom: '30px' }}>¿Quieres hacer una factura preforma de este presupuesto?</div>
                              <div>
                                    <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={handleClosePreform}>
                                          {' '}
                                          No
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={generatePreform}>
                                          {' '}
                                          Sí{' '}
                                    </Button>
                              </div>
                        </Box>
                  </Modal>
            </>
      );
}
