import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import CrudForm from '../../../crud/CrudForm';
import { categoryService as service, ivatypeService, taxtypeService } from '../../../../services';
import { useTranslation } from 'react-i18next';

const initElement = {
      name: null,
      shortDescription: null,
      imageprin: null,
      ivadefault: { id: null, name: '' },
      tasaDefault: { id: null, name: '' },
      maxDiscount: null,
      category: { id: null, name: '' },
};

export default function FormCategory() {
      const { t, i18n } = useTranslation();

      const estructureform = [
            {
                  type: 'input',
                  name: 'code',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'name',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'maxDiscount',
                  inputtype: 'number',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'category.id',
                  service: service.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'ivadefault.id',
                  service: ivatypeService.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'tasaDefault.id',
                  service: taxtypeService.combo,
                  col: 4,
            },
            {
                  type: 'editor',
                  name: 'shortDescription',
                  col: 8,
            },
            {
                  type: 'image',
                  name: 'imageprin',
                  col: 4,
            },
      ];

      const recoveryElement = function (objectedit, element) {
            objectedit['name'] = element.name ? element.name : '';
            objectedit['maxDiscount'] = element.maxDiscount ? element.maxDiscount : false;
            objectedit['shortDescription'] = element.shortDescription ? element.shortDescription : '';
            objectedit['imageprin'] = element.imageprin ? element.imageprin : '';
            objectedit['category'] = element.category ? element.category : { id: null, name: '' };
            objectedit['ivadefault'] = element.ivadefault ? element.ivadefault : { id: null, name: '' };
            objectedit['tasaDefault'] = element.tasaDefault ? element.tasaDefault : { id: null, name: '' };

            return objectedit;
      };

      const isValid = function (object) {
            if (!object['name']) {
                  return t('categories.error.name');
            }

            return null;
      };

      return <CrudForm objectform={{ ...initElement }} estructureform={estructureform} i18n={'categories'} urledit={'/categories/edit/'} urlCancel={'/categories'} service={service} recoveryElement={recoveryElement} valid={isValid} />;
}
