export const setValueRowProductWithoutTax = (setValueField, objectform, value, valueform, setState, id, aux, prices, clientGeneral) => {
      debugger;
      if (aux) {
            var valueFormAux = { ...valueform };

            console.log('ENTRA en producto' + JSON.stringify(aux));

            var newobject = objectform;

            var product = aux;
            newobject = setValueField(newobject, 'amount', null);
            newobject = setValueField(newobject, 'name', product.code + ' - ' + product.name);
            var price = 0;
            if (prices.has(product.id)) {
                  price = prices.get(product.id);
            } else {
                  price = product.price;
            }

            debugger;
            if (clientGeneral.diffprice != 0) {
                  price += clientGeneral.diffprice;
            }

            newobject = setValueField(newobject, 'price', prices.get(product.id));

            if (product.lote) {
                  newobject = setValueField(newobject, 'lote', product.lote);
            }
            var percentIva = clientGeneral?.exentoIva ? 0 : product.iva.percent;
            newobject = setValueField(newobject, 'percentIva', percentIva);
            var percentEqui = clientGeneral?.recargoEqui ? product.iva.recagoPercent : 0;
            newobject = setValueField(newobject, 'percentRecargo', percentEqui);
            var base = product.price;
            newobject = setValueField(newobject, 'base', base.toFixed(2));
            var iva = base * (percentIva / 100);
            newobject = setValueField(newobject, 'iva', iva.toFixed(2));
            var recargo = base * (percentEqui / 100.0);
            newobject = setValueField(newobject, 'recargo', recargo.toFixed(2));
            var total = base + iva + recargo;
            newobject = setValueField(newobject, 'total', total.toFixed(2));
            newobject = setValueField(newobject, 'total', total.toFixed(2));

            var rv = valueform[id];

            rv['name'] = product.code + ' - ' + product.name;
            rv['amount'] = null;
            rv['lote'] = product.lote ? product.lote : '';
            rv['price'] = price.toFixed(2);
            rv['tax'] = 0;
            rv['discount'] = 0;
            rv['discountPercent'] = 0;
            rv['base'] = base.toFixed(2);
            rv['iva'] = iva.toFixed(2);
            rv['recargo'] = recargo.toFixed(2);
            rv['total'] = total.toFixed(2);

            var update = {
                  valueform: valueFormAux,
                  objectform: newobject,
            };
            setState((currentstate) => ({ ...currentstate, ...update }));

            return newobject;
      } else {
            return objectform;
      }
};

export const setValueAmoutWithoutTax = (setValueField, objectform, value, valueform, setState, id, aux) => {
      var newobject = { ...objectform };
      newobject = setValueField(newobject, 'ammount', value);

      var valueFormAux = { ...valueform };
      var rv = valueform[id];
      rv['ammount'] = value;

      if (value) {
            var ammount = +value;
            var price = rv['price'] ? (isNaN(+rv['price']) ? 0 : +rv['price']) : 0;
            var percentIva = rv['percentIva'] ? (isNaN(+rv['percentIva']) ? 0 : +rv['percentIva']) : 0;
            var percentRecargo = rv['percentRecargo'] ? (isNaN(+rv['percentRecargo']) ? 0 : +rv['percentRecargo']) : 0;

            var discount = rv['discount'] ? (isNaN(+rv['discount']) ? 0 : +rv['discount']) : 0;
            var discountPercent = rv['discountPercent'] ? (isNaN(+rv['discountPercent']) ? 0 : +rv['discountPercent']) : 0;

            var tax = 0;

            var base = price * ammount;

            if (discountPercent > 0) {
                  discount = base * (1 - discountPercent / 100);
                  base = base * (discountPercent / 100);
            } else {
                  discount = 0;
            }
            var iva = base * (percentIva / 100);
            var recargo = base * (percentRecargo / 100.0);
            var total = base + iva + recargo;

            newobject = setValueField(newobject, 'base', base.toFixed(2));
            newobject = setValueField(newobject, 'iva', iva.toFixed(2));
            newobject = setValueField(newobject, 'recargo', recargo.toFixed(2));
            newobject = setValueField(newobject, 'total', total.toFixed(2));

            rv['base'] = base.toFixed(2);
            rv['iva'] = iva.toFixed(2);
            rv['recargo'] = recargo.toFixed(2);
            rv['total'] = total.toFixed(2);
            rv['discount'] = discount.toFixed(2);
      } else {
            newobject = setValueField(newobject, 'base', 0);
            newobject = setValueField(newobject, 'iva', 0);
            newobject = setValueField(newobject, 'recargo');
            newobject = setValueField(newobject, 'total');

            rv['base'] = '0.00';
            rv['iva'] = '0.00';
            rv['recargo'] = '0.00';
            rv['total'] = '0.00';
            rv['discount'] = '0.00';
      }

      var update = {
            valueform: valueFormAux,
            objectform: newobject,
      };
      setState((currentstate) => ({ ...currentstate, ...update }));

      return newobject;
};

export const setValuePriceWithoutTax = (setValueField, objectform, value, valueform, setState, id, aux) => {
      var newobject = { ...objectform };
      newobject = setValueField(newobject, 'price', value);

      var valueFormAux = { ...valueform };
      var rv = valueform[id];
      rv['price'] = value;

      if (value) {
            var ammount = rv['amount'] ? (isNaN(+rv['amount']) ? 0 : +rv['amount']) : 0;
            var price = +value;
            var percentIva = rv['percentIva'] ? (isNaN(+rv['percentIva']) ? 0 : +rv['percentIva']) : 0;
            var percentRecargo = rv['percentRecargo'] ? (isNaN(+rv['percentRecargo']) ? 0 : +rv['percentRecargo']) : 0;

            var discount = rv['discount'] ? (isNaN(+rv['discount']) ? 0 : +rv['discount']) : 0;
            var discountPercent = rv['discountPercent'] ? (isNaN(+rv['discountPercent']) ? 0 : +rv['discountPercent']) : 0;

            var tax = 0;

            var base = price * ammount;

            if (discountPercent > 0) {
                  discount = base * (1 - discountPercent / 100);
                  base = base * (discountPercent / 100);
            } else {
                  discount = 0;
            }

            var iva = base * (percentIva / 100);
            var recargo = base * (percentRecargo / 100.0);
            var total = base + iva + recargo;

            var newobject = objectform;
            newobject = setValueField(newobject, 'base', base.toFixed(2));
            newobject = setValueField(newobject, 'iva', iva.toFixed(2));
            newobject = setValueField(newobject, 'recargo', recargo.toFixed(2));
            newobject = setValueField(newobject, 'total', total.toFixed(2));

            rv['base'] = base.toFixed(2);
            rv['iva'] = iva.toFixed(2);
            rv['recargo'] = recargo.toFixed(2);
            rv['total'] = total.toFixed(2);
            rv['discount'] = discount.toFixed(2);
      } else {
            newobject = setValueField(newobject, 'base', 0);
            newobject = setValueField(newobject, 'iva', 0);
            newobject = setValueField(newobject, 'recargo');
            newobject = setValueField(newobject, 'total');

            rv['base'] = '0.00';
            rv['iva'] = '0.00';
            rv['recargo'] = '0.00';
            rv['total'] = '0.00';
            rv['discount'] = '0.00';
      }

      var update = {
            valueform: valueFormAux,
            objectform: newobject,
      };
      setState((currentstate) => ({ ...currentstate, ...update }));

      return newobject;
};

export const setValueIvaWithoutTax = (setValueField, objectform, value, valueform, setState, id, aux) => {
      var newobject = { ...objectform };
      newobject = setValueField(newobject, 'percentIva', value);

      var valueFormAux = { ...valueform };
      var rv = valueform[id];
      rv['percentIva'] = value;

      if (value) {
            var ammount = rv['amount'] ? (isNaN(+rv['amount']) ? 0 : +rv['amount']) : 0;
            var price = rv['price'] ? (isNaN(+rv['price']) ? 0 : +rv['price']) : 0;
            var percentIva = +value;
            var percentRecargo = rv['percentRecargo'] ? (isNaN(+rv['percentRecargo']) ? 0 : +rv['percentRecargo']) : 0;

            var discount = rv['discount'] ? (isNaN(+rv['discount']) ? 0 : +rv['discount']) : 0;
            var discountPercent = rv['discountPercent'] ? (isNaN(+rv['discountPercent']) ? 0 : +rv['discountPercent']) : 0;

            var tax = 0;

            var base = price * ammount;

            if (discountPercent > 0) {
                  discount = base * (1 - discountPercent / 100);
                  base = base * (discountPercent / 100);
            } else {
                  discount = 0;
            }

            var iva = base * (percentIva / 100);
            var recargo = base * (percentRecargo / 100.0);
            var total = base + iva + recargo;

            newobject = setValueField(newobject, 'base', base.toFixed(2));
            newobject = setValueField(newobject, 'iva', iva.toFixed(2));
            newobject = setValueField(newobject, 'recargo', recargo.toFixed(2));
            newobject = setValueField(newobject, 'total', total.toFixed(2));

            rv['base'] = base.toFixed(2);
            rv['iva'] = iva.toFixed(2);
            rv['recargo'] = recargo.toFixed(2);
            rv['total'] = total.toFixed(2);
            rv['discount'] = discount.toFixed(2);
      } else {
            newobject = setValueField(newobject, 'base', 0);
            newobject = setValueField(newobject, 'iva', 0);
            newobject = setValueField(newobject, 'recargo');
            newobject = setValueField(newobject, 'total');

            rv['base'] = '0.00';
            rv['iva'] = '0.00';
            rv['recargo'] = '0.00';
            rv['total'] = '0.00';
            rv['discount'] = '0.00';
      }

      var update = {
            valueform: valueFormAux,
            objectform: newobject,
      };
      setState((currentstate) => ({ ...currentstate, ...update }));

      return newobject;
};

export const setValueEquivalenciaWithoutTax = (setValueField, objectform, value, valueform, setState, id, aux) => {
      var newobject = { ...objectform };
      newobject = setValueField(newobject, 'percentRecargo', value);

      var valueFormAux = { ...valueform };
      var rv = valueform[id];
      rv['percentRecargo'] = value;

      if (value) {
            var ammount = rv['amount'] ? (isNaN(+rv['amount']) ? 0 : +rv['amount']) : 0;
            var price = rv['price'] ? (isNaN(+rv['price']) ? 0 : +rv['price']) : 0;
            var percentIva = rv['percentIva'] ? (isNaN(+rv['percentIva']) ? 0 : +rv['percentIva']) : 0;
            var percentRecargo = +value;
            var tax = 0;

            var base = price * ammount;
            var iva = base * (percentIva / 100);
            var recargo = base * (percentRecargo / 100.0);
            var total = base + iva + recargo;

            newobject = setValueField(newobject, 'base', base.toFixed(2));
            newobject = setValueField(newobject, 'iva', iva.toFixed(2));
            newobject = setValueField(newobject, 'recargo', recargo.toFixed(2));
            newobject = setValueField(newobject, 'total', total.toFixed(2));

            rv['base'] = base.toFixed(2);
            rv['iva'] = iva.toFixed(2);
            rv['recargo'] = recargo.toFixed(2);
            rv['total'] = total.toFixed(2);
      } else {
            newobject = setValueField(newobject, 'base', 0);
            newobject = setValueField(newobject, 'iva', 0);
            newobject = setValueField(newobject, 'recargo');
            newobject = setValueField(newobject, 'total');

            rv['base'] = '0.00';
            rv['iva'] = '0.00';
            rv['recargo'] = '0.00';
            rv['total'] = '0.00';
      }

      var update = {
            valueform: valueFormAux,
            objectform: newobject,
      };
      setState((currentstate) => ({ ...currentstate, ...update }));

      return newobject;
};

export const updateRowsWithoutTax = (setValueField, objectform, rows, setSate, valueform) => {
      var totalLines = 0.0;

      var base = 0;
      var iva = 0;
      var recargo = 0;
      var total = 0;
      var tax = 0;

      var ivasLine: any[] = [];
      var recargosLine: any[] = [];
      var ivas = new Map<number, any>();
      var recargos = new Map<number, any>();

      for (let row of rows) {
            if (row['total']) {
                  base += +row['base'];
                  iva += +row['iva'];
                  recargo = +row['recargo'];
                  total += +row['total'];
                  tax += 0;

                  if (ivas.has(+row['percentIva'])) {
                        ivas.set(+[row['percentIva']], { percentIva: row['percentIva'], iva: +row['iva'] + ivas.get(+row['percentIva']).iva });
                  } else {
                        ivas.set(+[row['percentIva']], { percentIva: row['percentIva'], iva: +row['iva'] });
                  }

                  if (recargos.has(+row['percentRecargo'])) {
                        recargos.set(+[row['percentRecargo']], { percent: row['percentRecargo'], amount: +row['recargo'] + recargos.get(+row['percentRecargo']).amount });
                  } else {
                        recargos.set(+[row['percentRecargo']], { percent: row['percentRecargo'], amount: +row['recargo'] });
                  }
            }
      }

      ivas.forEach((value, key) => {
            ivasLine.push(value);
      });

      recargos.forEach((value, key) => {
            recargosLine.push(value);
      });

      var disper = +objectform['discountPercent'];
      var dis = (base * (disper / 100.0)).toFixed(2);

      if (disper && disper > 0) {
            ivasLine = [];
            recargosLine = [];
            ivas = new Map<number, any>();
            recargos = new Map<number, any>();

            base = 0;
            iva = 0;
            recargo = 0;
            total = 0;
            tax = 0;

            for (let row of rows) {
                  if (row['total']) {
                        var priceLine = +row['price'] - (+row['price'] * disper) / 100;
                        var taxLine = 0;
                        var baseLine = priceLine * +row['amount'];
                        var ivaLine = baseLine * (+row['percentIva'] / 100);
                        var recargoLine = baseLine * (+row['percentRecargo'] / 100);
                        var totalLine = baseLine + ivaLine + recargoLine;

                        base += baseLine;
                        iva += ivaLine;
                        recargo += recargoLine;
                        total += totalLine;
                        tax += taxLine;

                        if (ivas.has(+row['percentIva'])) {
                              ivas.set(+[row['percentIva']], { percentIva: row['percentIva'], iva: ivaLine + ivas.get(+row['percentIva']).iva });
                        } else {
                              ivas.set(+[row['percentIva']], { percentIva: row['percentIva'], iva: ivaLine });
                        }

                        if (recargos.has(+row['percentRecargo'])) {
                              recargos.set(+[row['percentRecargo']], { percent: row['percentRecargo'], amount: recargoLine + recargos.get(+row['percentRecargo']).amount });
                        } else {
                              recargos.set(+[row['percentRecargo']], { percent: row['percentRecargo'], amount: recargoLine });
                        }
                  }
            }

            ivas.forEach((value, key) => {
                  ivasLine.push(value);
            });

            recargos.forEach((value, key) => {
                  recargosLine.push(value);
            });
      }

      let valueFormAux = { ...valueform };
      valueFormAux['total'] = total.toFixed(2);
      valueFormAux['discount'] = dis;
      valueFormAux['base'] = base.toFixed(2);
      valueFormAux['iva'] = iva.toFixed(2);
      valueFormAux['tax'] = tax.toFixed(2);
      valueFormAux['equivalencia'] = recargo.toFixed(2);

      var newobject = { ...objectform };
      newobject = setValueField(newobject, 'total', total);
      newobject = setValueField(newobject, 'discount', dis);
      newobject = setValueField(newobject, 'base', base);
      newobject = setValueField(newobject, 'iva', iva);
      newobject = setValueField(newobject, 'tax', tax);
      newobject = setValueField(newobject, 'equivalencia', recargo);
      newobject = setValueField(newobject, 'ivasline', ivasLine);
      newobject = setValueField(newobject, 'recargosline', recargosLine);

      var update = {
            objectform: newobject,
            valueform: valueFormAux,
      };

      setSate((currentState) => ({ ...currentState, ...update }));

      return newobject;
};

export const updateDiscountTotalWithoutTax = (setValueField, objectform, value, valueform, setState) => {
      var rows = objectform['lines'];

      var totalLines = 0.0;

      var base = 0;
      var iva = 0;
      var recargo = 0;
      var total = 0;
      var tax = 0;

      var ivasLine: any[] = [];
      var recargosLine: any[] = [];
      var ivas = new Map<number, any>();
      var recargos = new Map<number, any>();

      for (let row of rows) {
            if (row['total']) {
                  base += +row['base'];
                  iva += +row['iva'];
                  recargo = +row['recargo'];
                  total += +row['total'];
                  tax += 0;

                  if (ivas.has(+row['percentIva'])) {
                        ivas.set(+[row['percentIva']], { percentIva: row['percentIva'], iva: +row['iva'] + ivas.get(+row['percentIva']).iva });
                  } else {
                        ivas.set(+[row['percentIva']], { percentIva: row['percentIva'], iva: +row['iva'] });
                  }

                  if (recargos.has(+row['percentRecargo'])) {
                        recargos.set(+[row['percentRecargo']], { percent: row['percentRecargo'], amount: +row['recargo'] + recargos.get(+row['percentRecargo']).amount });
                  } else {
                        recargos.set(+[row['percentRecargo']], { percent: row['percentRecargo'], amount: +row['recargo'] });
                  }
            }
      }

      var disper = +value;
      var dis = (base * (disper / 100.0)).toFixed(2);
      if (disper && disper > 0) {
            ivasLine = [];
            recargosLine = [];
            ivas = new Map<number, any>();
            recargos = new Map<number, any>();

            base = 0;
            iva = 0;
            recargo = 0;
            total = 0;
            tax = 0;

            for (let row of rows) {
                  if (row['total']) {
                        var priceLine = +row['price'] - (+row['price'] * disper) / 100;
                        var taxLine = 0;
                        var baseLine = priceLine * +row['amount'];
                        var ivaLine = baseLine * (+row['percentIva'] / 100);
                        var recargoLine = baseLine * (+row['percentRecargo'] / 100);
                        var totalLine = baseLine + ivaLine + recargoLine;

                        base += baseLine;
                        iva += ivaLine;
                        recargo += recargoLine;
                        total += totalLine;
                        tax += taxLine;

                        if (ivas.has(+row['percentIva'])) {
                              ivas.set(+[row['percentIva']], { percentIva: row['percentIva'], iva: ivaLine + ivas.get(+row['percentIva']).iva });
                        } else {
                              ivas.set(+[row['percentIva']], { percentIva: row['percentIva'], iva: ivaLine });
                        }

                        if (recargos.has(+row['percentRecargo'])) {
                              recargos.set(+[row['percentRecargo']], { percent: row['percentRecargo'], amount: recargoLine + recargos.get(+row['percentRecargo']).amount });
                        } else {
                              recargos.set(+[row['percentRecargo']], { percent: row['percentRecargo'], amount: recargoLine });
                        }
                  }
            }
      }

      ivas.forEach((value, key) => {
            ivasLine.push(value);
      });

      recargos.forEach((value, key) => {
            recargosLine.push(value);
      });

      let valueFormAux = { ...valueform };
      valueFormAux['discountPercent'] = value;
      valueFormAux['total'] = total.toFixed(2);
      valueFormAux['discount'] = dis;
      valueFormAux['base'] = base.toFixed(2);
      valueFormAux['iva'] = iva.toFixed(2);
      valueFormAux['tax'] = tax.toFixed(2);
      valueFormAux['equivalencia'] = recargo.toFixed(2);

      var newobject = { ...objectform };
      newobject = setValueField(newobject, 'discountPercent', value);
      newobject = setValueField(newobject, 'total', total.toFixed(2));
      newobject = setValueField(newobject, 'discount', dis);
      newobject = setValueField(newobject, 'base', base.toFixed(2));
      newobject = setValueField(newobject, 'iva', iva.toFixed(2));
      newobject = setValueField(newobject, 'tax', tax.toFixed(2));
      newobject = setValueField(newobject, 'equivalencia', recargo.toFixed(2));
      newobject['ivasline'] = ivasLine;
      newobject = setValueField(newobject, 'recargosline', recargosLine);

      var update = {
            objectform: newobject,
            valueform: valueFormAux,
      };

      setState((currentState) => ({ ...currentState, ...update }));

      return newobject;
};
