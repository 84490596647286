import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TableMui from '@mui/material/Table';
import TableBodyMui from '@mui/material/TableBody';
import TableCellMui from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHeadMui from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRowMui from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system';
import Moment from 'react-moment';
import Checkbox from '@mui/material/Checkbox';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { Favorite, FavoriteBorder } from '@mui/icons-material';

const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'white',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
};

function TablePaginationActions(props) {
      const theme = useTheme();
      const { count, page, rowsPerPage, onPageChange } = props;

      const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
      };

      const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
      };

      const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
      };

      const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      };

      return (
            <div>
                  <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                  </IconButton>
                  <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  </IconButton>
                  <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}{' '}
                  </IconButton>
                  <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}{' '}
                  </IconButton>
            </div>
      );
}

TablePaginationActions.propTypes = {
      count: PropTypes.number.isRequired,
      onChangePage: PropTypes.func.isRequired,
      page: PropTypes.number.isRequired,
      rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationActionsTable(props) {
      const t = props.t;

      const createEmpty = () => {
            var ar: any[] = [];

            return ar;
      };
      const [_, forceUpdate] = useReducer((x) => x + 1, 0);

      const [
            { idrow, rowsPerPage, deleteaction, iconDeleteAction, iconEditAction, colorIconDeleteAction, colorIconEditAction, actionQuestion, open, hiddenpagination, moreActions, checked, selectedRow, page, selectedall, rows, rowsTotal },
            setState,
      ] = useState({
            idrow: '',
            rowsPerPage: props.rowsPerPage ? props.rowsPerPage : 5,
            deleteaction: props.deleteaction ? props.deleteaction : false,
            iconDeleteAction: props.iconDeleteAction ? props.iconDeleteAction : <DeleteIcon />,
            iconEditAction: props.iconEditAction ? props.iconEditAction : <EditIcon />,
            colorIconDeleteAction: props.colorIconDeleteAction ? props.colorIconDeleteAction : 'red',
            colorIconEditAction: props.colorIconEditAction ? props.colorIconEditAction : '#3c3c44',
            actionQuestion: props.actionQuestion ? props.actionQuestion : '¿Estas seguro que deseas borrarlo?',
            open: false,
            hiddenpagination: props.hiddenpagination ? props.hiddenpagination : false,
            moreActions: props.moreActions ? props.moreActions : [],
            checked: createEmpty(),
            selectedRow: createEmpty(),
            page: props.page,
            selectedall: false,
            rows: props.rows,
            rowsTotal: props.rowsTotal,
      });

      const defaultValues = props.defaultValues ? props.defaultValues : {};
      const paintvalue = props.paintvalue ? props.paintvalue : {};
      const fields = props.fields;
      const fieldId = props.fieldId;
      const selectable = props.selectable ? props.selectable : false;
      const handleChangePageParent = props.changePage;

      const emptyRows = rows ? rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage) : 0;

      const handleChangePage = (event, newPage) => {
            setState((currentState) => ({ ...currentState, ['page']: newPage }));
            handleChangePageParent(newPage, rowsPerPage);
      };

      useEffect(() => {
            setState((currentState) => ({ ...currentState, ['page']: props.page }));
      }, [props.page]);

      const handleChangeRowsPerPage = (event) => {
            const sizePage = parseInt(event.target.value, 10);
            //setRowsPerPage(sizePage);
            var update = {
                  page: 0,
                  rowsPerPage: sizePage,
            };
            setState((currentState) => ({ ...currentState, ...update }));
            handleChangePageParent(0, sizePage);
      };

      useEffect(() => {
            setState((currentState) => ({ ...currentState, ['rowsPerPage']: props.rowsPerPage }));
      }, [props.rowsPerPage]);

      const getValueField = (object, field) => {
            if (object) {
                  if (field.includes('.')) {
                        const parts = field.split('.');
                        var objfield = object[parts[0]];
                        if (Array.isArray(objfield)) {
                              var result = <></>;
                              for (let entry of objfield) {
                                    var linr = getValueField(entry, field.substring(parts[0].length + 1));
                                    result = (
                                          <>
                                                {result}
                                                {linr}
                                                <br />
                                          </>
                                    );
                              }
                              return result;
                        } else {
                              var r = getValueField(objfield, field.substring(parts[0].length + 1));
                              return <>{r}</>;
                        }
                  } else {
                        if (paintvalue[field]) {
                              const r = object[field];
                              return <>{paintvalue[field](r)}</>;
                        } else if ((field == 'base' || field == 'discount' || field == 'iva' || field == 'total' || field == 'price' || field == 'lastBuyPrice' || field == 'percentPvp') && object[field] && object[field] != '') {
                              const r = object[field];
                              return <>{r.toFixed(2)}</>;
                        } else if ((field == 'image' || field == 'picture' || field == 'imageprin') && object[field] && object[field] != '') {
                              const url = getUrlImage(object[field]);
                              return <img src={url} style={{ width: '70px', height: 'auto', maxWidth: '100%', minWidth: '70px' }} />;
                        } else if (field == 'day' && object[field] && object[field] != '') {
                              switch (object[field]) {
                                    case 1:
                                          return (
                                                <>
                                                      <label> {'Lunes'} </label>
                                                      <br />
                                                </>
                                          );
                                    case 2:
                                          return (
                                                <>
                                                      <label> {'LunMarteses'} </label>
                                                      <br />
                                                </>
                                          );
                                    case 3:
                                          return (
                                                <>
                                                      <label> {'Miercoles'} </label>
                                                      <br />
                                                </>
                                          );
                                    case 4:
                                          return (
                                                <>
                                                      <label> {'Jueves'} </label>
                                                      <br />
                                                </>
                                          );
                                    case 5:
                                          return (
                                                <>
                                                      <label> {'Viernes'} </label>
                                                      <br />
                                                </>
                                          );
                                    case 6:
                                          return (
                                                <>
                                                      <label> {'Sabado'} </label>
                                                      <br />
                                                </>
                                          );
                                    case 7:
                                          return (
                                                <>
                                                      <label> {'Domingo'} </label>
                                                      <br />
                                                </>
                                          );
                              }
                        } else if ((field.toLowerCase().includes('date') || field.includes('pickUp') || field.includes('delivery')) && object[field] && object[field] != '') {
                              return (
                                    <>
                                          <Moment date={object[field]} format={'DD/MM/yyyy'} />
                                          <br />
                                    </>
                              );
                        } else if (field.toLowerCase().includes('vimeo')) {
                              var rvimeo = object[field];
                              if (rvimeo && rvimeo != '') {
                                    var idvideo = rvimeo.replace('https://vimeo.com/', '');
                                    return <iframe src={'https://player.vimeo.com/video/' + idvideo} width="310" height="180" style={{ maxWidth: '80%' }}></iframe>;
                              } else {
                                    return <></>;
                              }
                        } else {
                              var r2 = object[field];
                              if (props.paintCell && props.paintCell[field]) {
                                    return props.paintCell[field](r2, object);
                              } else if (typeof r2 === 'boolean' && r2) {
                                    return <CheckIcon style={{ color: 'green' }} />;
                              } else if (typeof r2 === 'boolean' && !r2) {
                                    return <ClearIcon style={{ color: 'red' }} />;
                              } else if (!r2 || r2 == '' || r2 == null) {
                                    if (defaultValues && defaultValues[field]) {
                                          return <>{defaultValues[field]}</>;
                                    } else {
                                          return <></>;
                                    }
                              } else {
                                    return (
                                          <>
                                                {r2}
                                                <br />
                                          </>
                                    );
                              }
                        }
                  }
            } else {
                  return '';
            }
      };

      const getUrlImage = function (namefile) {
            if (namefile) {
                  if (namefile.startsWith('http')) {
                        return namefile;
                  } else {
                        var nameImage = namefile;
                        nameImage = nameImage.startsWith('/') ? nameImage : '/' + nameImage;
                        return process.env.REACT_APP_HOST + '/image' + nameImage;
                  }
            } else {
                  return '';
            }
      };

      const handlerDel = function (id) {
            var update = {
                  idrow: id,
                  open: true,
            };

            setState((currentState) => ({ ...currentState, ...update }));
      };

      const handlerDelConfirm = function () {
            console.log('DELETE ->' + idrow);
            setState((currentState) => ({ ...currentState, ['open']: false }));
            if (props.deleteactionHandler) {
                  props.deleteactionHandler(idrow);
            }
      };

      const deleteactionButton = function (row) {
            if (props.getDeleteButton) {
                  return props.getDeleteButton(row, handlerDel);
            } else {
                  return (
                        <Button variant="contained" color="primary" style={{ backgroundColor: colorIconDeleteAction, color: 'white' }} onClick={() => handlerDel(row[fieldId])}>
                              {' '}
                              {iconDeleteAction}{' '}
                        </Button>
                  );
            }
      };

      const editactionButton = function (row) {
            if (props.getEditButton) {
                  return props.getEditButton(row, handlerDobleClick);
            } else {
                  return (
                        <Button variant="contained" color="primary" style={{ backgroundColor: colorIconEditAction, color: 'white' }} onClick={() => handlerDobleClick(row[fieldId])}>
                              {' '}
                              {iconEditAction}{' '}
                        </Button>
                  );
            }
      };

      const handleClose = () => {
            setState((currentState) => ({ ...currentState, ['open']: false }));
      };

      const handlerDobleClick = function (id) {
            if (props.selectHandler) {
                  props.selectHandler(id);
            }
      };

      const paintCell = (row, field) => {
            if (field.includes('|')) {
                  var fields = field.split('|');
                  var value = '';
                  return (
                        <TableCell component="td" scope="row" sx={{ color: props.conditionColor ? props.conditionColor(row) : 'black !important' }}>
                              <div>
                                    <SpanLabelCell>{props.labels && props.labels[field] ? props.labels[field] : t(props.i18n + '.' + 'field.' + field)}: </SpanLabelCell> {fields.map((element) => getValueField(row, element))}
                              </div>{' '}
                        </TableCell>
                  );
            } else {
                  return (
                        <TableCell component="td" scope="row" sx={{ color: props.conditionColor ? props.conditionColor(row) : 'black !important' }}>
                              <SpanLabelCell>{props.labels && props.labels[field] ? props.labels[field] : t(props.i18n + '.' + 'field.' + field)}: </SpanLabelCell> {getValueField(row, field)}
                        </TableCell>
                  );
            }
      };

      const checkedRow = (id) => {
            var checkedaux = checked;
            if (checked.includes(id)) {
                  const index = checked.indexOf(id, 0);
                  checkedaux.splice(index, 1);
                  selectedRow[id] = false;
            } else {
                  checkedaux.push(id);
                  selectedRow[id] = true;
            }

            var selectedAllAux = true;
            var count = 0;
            for (let b in selectedRow) {
                  selectedAllAux = selectedAllAux && selectedRow[b];
                  count++;
            }
            selectedAllAux = selectedAllAux && count == rows.length;

            var update = {
                  checked: checkedaux,
                  selectedRow: selectedRow,
                  selectedall: selectedAllAux,
            };
            setState((currentState) => ({ ...currentState, ...update }));

            if (props.handlerSelected) {
                  props.handlerSelected(checkedaux);
            }

            if (props.handlerSelectedv2) {
                  props.handlerSelectedv2(checkedaux, rows);
            }
      };

      const checkedAll = () => {
            if (selectedall) {
                  var update = {
                        checked: createEmpty(),
                        selectedRow: createEmpty(),
                        selectedall: false,
                  };
                  setState((currentState) => ({ ...currentState, ...update }));
                  if (props.handlerSelected) {
                        props.handlerSelected(createEmpty());
                  }

                  if (props.handlerSelectedv2) {
                        props.handlerSelectedv2(createEmpty(), rows);
                  }
            } else {
                  var checkedaux: any[] = [];
                  var selectedRowAux: any[] = [];
                  rows.forEach((r) => {
                        checkedaux.push(r[fieldId]);
                        selectedRowAux[r[fieldId]] = true;
                  });

                  var update2 = {
                        checked: checkedaux,
                        selectedRow: selectedRowAux,
                        selectedall: true,
                  };
                  setState((currentState) => ({ ...currentState, ...update2 }));

                  if (props.handlerSelected) {
                        props.handlerSelected(checkedaux);
                  }

                  if (props.handlerSelectedv2) {
                        props.handlerSelectedv2(checkedaux, rows);
                  }
            }

            forceUpdate();
      };

      useEffect(() => {
            return () => {
                  setState({
                        idrow: '',
                        rowsPerPage: 10,
                        deleteaction: false,
                        iconDeleteAction: <DeleteIcon />,
                        iconEditAction: <EditIcon />,
                        colorIconDeleteAction: 'red',
                        colorIconEditAction: '#3c3c44',
                        actionQuestion: '¿Estas seguro que deseas borrarlo?',
                        open: false,
                        hiddenpagination: false,
                        moreActions: [],
                        checked: createEmpty(),
                        selectedRow: createEmpty(),
                        page: props.page,
                        selectedall: false,
                        rows: props.rows,
                        rowsTotal: props.rowsTotal,
                  });
            };
      }, []);

      useEffect(() => {
            var update = {
                  rows: props.rows,
                  checked: createEmpty(),
                  selectedRow: createEmpty(),
            };
            setState((currentState) => ({ ...currentState, ...update }));
      }, [props.rows]);

      useEffect(() => {
            var update = {
                  rowsTotal: props.rowsTotal,
            };
            setState((currentState) => ({ ...currentState, ...update }));
      }, [props.rowsTotal]);

      return (
            <DivTable>
                  <TableContainer
                        component={Paper}
                        sx={{
                              '@media (max-width: 767px)': {
                                    borderRadius: '0px',
                                    boxShadow: 'none',
                                    background: 'transparent',
                              },
                        }}
                  >
                        <Table aria-label="custom pagination table" size="small">
                              <TableHead>
                                    <TableRow>
                                          {selectable && (
                                                <TableCell component="th" scope="row" width="20px">
                                                      {' '}
                                                      <Checkbox checked={selectedall} onChange={(evt) => checkedAll()} />
                                                </TableCell>
                                          )}
                                          {fields.map((h) => (
                                                <TableCell component="th" scope="row" onClick={props.orderColumns && props.orderColumns[h] && props.clickHeader ? (evt) => props.clickHeader(props.orderColumns[h]) : (evt) => {}}>
                                                      {props.labels && props.labels[h] ? props.labels[h] : t(props.i18n + '.' + 'field.' + h)}{' '}
                                                      {props.orderColumns && props.orderColumns[h] && props.clickHeader ? (
                                                            props.columOrder && props.columOrder == props.orderColumns[h] ? (
                                                                  props.columOrderDirection ? (
                                                                        <NorthIcon />
                                                                  ) : (
                                                                        <SouthIcon />
                                                                  )
                                                            ) : (
                                                                  <SwapVertIcon />
                                                            )
                                                      ) : (
                                                            <></>
                                                      )}
                                                </TableCell>
                                          ))}
                                          {moreActions && moreActions.map((ma) => <TableCell component="th" scope="row" width="40px"></TableCell>)}
                                          {props.editable ? <TableCell component="th" scope="row" width="40px"></TableCell> : ''}
                                          {deleteaction ? <TableCell component="th" scope="row" width="40px"></TableCell> : ''}
                                    </TableRow>
                              </TableHead>
                              {rows && (
                                    <TableBody>
                                          {rows.map((row) => (
                                                <TableRow key={row[fieldId]} onDoubleClick={(event) => handlerDobleClick(row[fieldId])}>
                                                      {selectable && (
                                                            <TableCell component="th" scope="row" width="20px">
                                                                  {' '}
                                                                  <Checkbox key={'check' + row[fieldId]} checked={checked.includes(row[fieldId])} onChange={(evt) => checkedRow(row[fieldId])} />
                                                            </TableCell>
                                                      )}
                                                      {fields.map((field, col) => paintCell(row, field))}
                                                      {moreActions &&
                                                            moreActions.map((ma) =>
                                                                  !ma.condition || ma.condition(row) ? (
                                                                        <TableCell component="td" scope="row" variant="body" width="40px">
                                                                              <Button variant="contained" style={{ backgroundColor: colorIconEditAction, color: 'white' }} onClick={() => ma.handle(row)}>
                                                                                    {' '}
                                                                                    {ma.icon}{' '}
                                                                              </Button>
                                                                        </TableCell>
                                                                  ) : (
                                                                        <TableCell component="td" scope="row" variant="body" width="40px"></TableCell>
                                                                  )
                                                            )}
                                                      {props.editable && row && row[fieldId] ? (
                                                            <TableCell component="td" scope="row" variant="body" width="40px">
                                                                  {editactionButton(row)}
                                                            </TableCell>
                                                      ) : (
                                                            ''
                                                      )}
                                                      {deleteaction && row && row[fieldId] && (!props.conditionDelete || props.conditionDelete(row)) ? (
                                                            <TableCell component="td" scope="row" variant="body" width="40px">
                                                                  {deleteactionButton(row)}
                                                            </TableCell>
                                                      ) : deleteaction && row && row[fieldId] ? (
                                                            <TableCell component="td" scope="row" variant="body" width="40px"></TableCell>
                                                      ) : (
                                                            ''
                                                      )}
                                                </TableRow>
                                          ))}
                                    </TableBody>
                              )}
                        </Table>
                  </TableContainer>
                  {!hiddenpagination && (
                        <TablePagination
                              rowsPerPageOptions={[5, 10, 20, 40, 60, 80, 100]}
                              component="div"
                              count={rowsTotal}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                              labelRowsPerPage={t('table.rowsperpage')}
                              sx={{
                                    '.MuiTablePagination-spacer': {
                                          flex: '1 1 50%',
                                    },
                                    '@media (max-width: 1200px)': {
                                          '.MuiTablePagination-spacer': {
                                                flex: '1 1 30%',
                                          },
                                    },
                                    '@media (max-width: 767px)': {
                                          '& button:nth-child(1)': {
                                                display: 'none',
                                          },
                                          '& button:nth-child(4)': {
                                                display: 'none',
                                          },
                                          '& .MuiTablePagination-selectLabel': {
                                                display: 'none',
                                          },
                                          '& .MuiTablePagination-spacer': {
                                                display: 'none',
                                          },
                                    },
                              }}
                        />
                  )}
                  <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                        <Box sx={style}>
                              <div style={{ marginBottom: '30px' }}>{actionQuestion}</div>
                              <div>
                                    <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={handleClose}>
                                          {' '}
                                          No
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={handlerDelConfirm}>
                                          {' '}
                                          Sí{' '}
                                    </Button>
                              </div>
                        </Box>
                  </Modal>
            </DivTable>
      );
}

const Table = styled(TableMui)`
      @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
      }
`;

const TableBody = styled(TableBodyMui)`
      @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
      }
`;

const TableHead = styled(TableHeadMui)`
      @media (max-width: 767px) {
            display: none;
      }
`;

const TableRow = styled(TableRowMui)`
      @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            border-radius: 4px;
            box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
            margin-bottom: 1.5em;
            background: white;
      }
`;

const TableCell = styled(TableCellMui)`
      @media (max-width: 767px) {
            display: flex;
            border: none;
            flex: 0 0 100%;
      }
`;

const DivTable = styled('div')``;

const SpanLabelCell = styled('span')`
      font-style: italic;
      padding-right: 1em;
      color: darkgray;
      display: none;
      @media (max-width: 767px) {
            display: inline;
      }
`;
